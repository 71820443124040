import Vue from 'vue'
import App from './components/App.vue'
import VModal from 'vue-js-modal'
require('./bootstrap');

Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
}).$mount('#app');
Vue.use(VModal);