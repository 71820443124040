<template>
  <main class="app">
    <Quiz/>
  </main>
</template>

<script>
import Quiz from "./Quiz.vue";

export default {
  components: {
    Quiz
  }
};
</script>

<style lang="less">
  @import "../assets/less/application";
</style>