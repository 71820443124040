<template>
    <div class="container question-page">
      <img src="/img/top.png" alt="" class="top"/>
      <img src="img/left.png" alt="" class="left"/>
      <img src="/img/right.png" alt="" class="right"/>
      <img src="/img/logo.png" alt="" class="corner-logo"/>
      <transition name="fade" mode="out-in">
        <div class="box" v-if="currentQuestion != null" :key="currentQuestion">
          <p class="number" v-text="questionNumber"></p>
          <h1 class="question-txt" v-text="currentQuestion.title"></h1>
          <div class="question-items">
            <div class="question-item" v-for="(answer, i) in currentQuestion.options" :key="i"
                 @click="handleAnswer(answer.uid)" @mouseover.native="hovered = true" @mouseleave.native="hovered = false">
              <p class="question-item-number" v-text="answer.orderPoint"></p>
              <p class="question-item-txt" v-text="answer.title"></p>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      questions: [],
      currentQuestion: null,
      currentQuestionIndex: 0,
      answers: [],
    }
  },
  created: function () {
    axios.get(window.BASE_URL + '/api/quiz/questions').then((response) => {
      this.questions = response.data.data;
      this.currentQuestion = this.questions[0];
      this.loading = false;
    });
  },
  computed: {
    questionNumber() {
      return this.zeroPad(this.currentQuestionIndex + 1, 2);
    }
  },
  methods: {
    handleAnswer(answer) {
      this.answers.push(answer);
      this.goToQuestion(++this.currentQuestionIndex);
    },
    goToQuestion(questionIndex) {
      if (this.questions.hasOwnProperty(questionIndex)) {
        this.currentQuestion = this.questions[questionIndex];
      } else {
        this.submit();
      }
    },
    submit() {
      this.$emit('finish', this.answers);
    },
    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    }
  },
}
</script>