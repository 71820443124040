<template>
  <section>
    <div class="container result-page">
      <a href="/">
        <img src="img/top.png" alt="" class="top" />
      </a>
      <img src="img/left.png" alt="" class="left" />
      <img src="img/right.png" alt="" class="right" />
      <a href="/">
        <img src="/img/logo.png" alt="" class="corner-logo"/>
      </a>
      <div class="box" v-if="result != null">
        <img src="img/result.png" alt="" class="result-img" />
        <h1 class="title" v-text="result.title">Molinn þinn er Laufabrauðsmolinn!</h1>
        <img v-bind="{'src': imgPath}" alt="" class="candy" />
        <div class="wrapper">
          <div class="left">
            <div class="desc" v-text="result.description"></div>
            <ShareEmailForm :resultUID="result.uid" />
          </div>
          <div class="right">
            <img v-bind="{'src': imgPath}" alt="" class="candy" />
            <div class="share-data">
              <p class="txt">Svo máttu alveg deila með vinum á Facebook svo þeir geti fundið út hver þeirra moli er.</p>
              <a :href="result.shareLink" class="btn-blue"><img src="img/facebook.svg" alt=""/> Deila á facebook</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ShareEmailForm from "./ShareEmailForm";
import Picture from "./Picture";

export default {
  props: {
    result: {
      required: true,
      type: Object
    }
  },
  components: { ShareEmailForm, Picture },
  computed: {
    imgPath() {
      return window.imgPath + this.result.img_path;
    }
  },
  watch: {
    result: function (value) {}
  }
};
</script>