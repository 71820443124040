<template>
  <section>
    <div class="container home-page">
      <img src="img/main-img.png" alt="" class="bg-two" />
      <a href="#" class="btn" @click="start">Taktu þátt</a>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    start() {
      this.$emit('start', this.answers);
    }
  }
}
</script>