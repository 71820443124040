<template>
  <section>
    <img
        class="candy"
        v-bind="{'src': imgPath}"
        height="100%"
        width="100%"
    />
  </section>
</template>

<script>
export default {
  props: {
    url: {
      required: true,
      type: String
    }
  },
  computed: {
    imgPath() {
      return window.imgPath + this.url;
    }
  }
};
</script>