<template>
  <section>
    <form>
      <div class="form">
        <p class="form-txt">Þá veistu það!</p>
        <div class="form-desc">Taktu svo endilega þátt í gjafaleiknum okkar með því að skrá netfangið hér að neðan, og kannski vinnur þú ljúffengt konfekt frá Nóa Síríus. </div>
        <input placeholder="Sláðu inn netfang" type="email" v-model="form.email" :class="[emailInputClass()]"/>
        <button class="btn" @click.prevent="submit" :class="[buttonClass()]">Skrá þig í pottinn</button>
      </div>
    </form>
    <modal name="thanks-modal" :adaptive="true" classes="thanks-modal">
      <div class="happy-icon">
        <img src="img/happy-icon.png"/>
      </div>
      <div class="thanks-title">
        Takk fyrir þátttöku í leiknum okkar
      </div>
      <div class="thanks-desc">
        Við drögum vinningshafa á næstunni, fylgstu með á Facebook síðu <a href="https://www.facebook.com/noisirius/">Nóa Síríus</a>
      </div>
    </modal>
  </section>
</template>
<script>
export default {
  props: {
    resultUID: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      form: {
        email: null,
        name: "",
        resultUID: this.resultUID
      },
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    submit() {
      if (this.isEmailValid()) {
        axios.post(window.BASE_URL + '/api/quiz/share-email', this.form).then((response) => {
          if (response.status == 200) {
            this.$modal.show('thanks-modal');
          }
        });
      }
    },
    isEmailValid: function() {
      return !(this.form.email === "" || !this.reg.test(this.form.email));
    },
    emailInputClass: function () {
      return this.form.email === null ? '' : (this.isEmailValid() ? 'has-success' : 'has-error');
    },
    buttonClass: function () {
      return this.isEmailValid() ? '' : 'disabled'
    }
  }
};
</script>